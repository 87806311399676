export const products = [
  "14 Patti",
  "5 Patti",
  "7 Patti",
  "Bajuband",
  "Bali",
  "Chain",
  "Chhalla",
  "Chik Set",
  "Choker",
  "Chudi",
  "Dane (AP)",
  "Dane (Jo)",
  "Dane (V)",
  "Fali",
  "Haar",
  "Haar (Antique)",
  "Hay Om",
  "JR",
  "Kanchain",
  "Kandora",
  "LR",
  "MS",
  "MS (Highway)",
  "Nath",
  "P Set",
  "Patli",
  "Pendal (A)",
  "Pendal (F)",
  "Ranihaar",
  "RJ Bali",
  "RJ Chhalla",
  "RJ MS",
  "RJ Pendal",
  "RJ SD",
  "RJ Tops",
  "Seep",
  "Tika",
  "Topra",
  "Tops (A)",
  "Tops (F)",
  "Z (A)",
  "Z (Fix)",
  "Z (Khulle G)",
  "Zala",
  "Zala (FC)",
];
