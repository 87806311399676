import React from "react";
import { Header, CompleatedOrderes } from "../components";

const History = () => {
  return (
    <>
      <Header />
      <CompleatedOrderes />
    </>
  );
};

export default History;
